import * as React from "react";
import PropTypes from "prop-types";
import { Partytown } from "@builder.io/partytown/react";

const forward = [
  "dataLayer.push",
  "ttq.track",
  "ttq.page",
  "ttq.load",
  "fbq",
  "twq",
  "gtag",
  "_hsq.push",
  "Beacon",
  "Beacon.readyQueue.push",
];

const corsUrls = [
  "analytics.tiktok.com",
  "api.hubapi.com",
  "js.hs-analytics.net",
  "connect.facebook.net",
  "beacon-v2.helpscout.net",
  "www.google-analytics.com",
  "www.googletagmanager.com",
  "static.ads-twitter.com",
];

function resolveUrl(url) {
  if (corsUrls.some((corsUrl) => url.hostname.includes(corsUrl))) {
    const proxyUrl = new URL(`${location.origin}/~partytownproxy`);
    proxyUrl.searchParams.append("url", url);
    return proxyUrl;
  }
  return url;
}

function resolveSendBeaconRequestParameters(url) {
  return url.hostname.includes("analytics.google") ||
    url.hostname.includes("google-analytics")
    ? { keepalive: false }
    : {};
}

export default function Scripts({ children }) {
  if (window.location.hostname === "localhost") {
    return children;
  }

  return (
    <>
      <Partytown
        lib="/public/~partytown/"
        forward={forward}
        resolveUrl={resolveUrl}
        resolveSendBeaconRequestParameters={resolveSendBeaconRequestParameters}
      />

      {children}

      <script
        type="text/partytown"
        dangerouslySetInnerHTML={{
          __html: `
(function (w, d, s, l, i) {
  w['global'] = d;
  w[l] = w[l] || [];
  w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
  var f = d.getElementsByTagName(s)[0],
    j = d.createElement(s),
    dl = l != "dataLayer" ? "&l=" + l : "";
  j.async = true;
  j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
  f.parentNode.insertBefore(j, f);
})(window, document, "script", "dataLayer", "GTM-W4H3P7NG");
`,
        }}
      />

      <script
        type="text/partytown"
        dangerouslySetInnerHTML={{
          __html: `
(function (f, b, e, v, n, t, s) {
  if (f.fbq) return;
  n = f.fbq = function () {
    n.callMethod
      ? n.callMethod.apply(n, arguments)
      : n.queue.push(arguments);
  };
  if (!f._fbq) f._fbq = n;
  n.push = n;
  n.loaded = !0;
  n.version = "2.0";
  n.queue = [];
  t = b.createElement(e);
  t.async = !0;
  t.src = v;
  s = b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t, s);
})(
  window,
  document,
  "script",
  "https://connect.facebook.net/en_US/fbevents.js",
);
fbq("init", "1327048991626476");
fbq("track", "PageView");
`,
        }}
      />

      <script
        type="text/partytown"
        dangerouslySetInnerHTML={{
          __html: `
!(function (e, t, n, s, u, a) {
  e.twq ||
    ((s = e.twq =
      function () {
        s.exe ? s.exe.apply(s, arguments) : s.queue.push(arguments);
      }),
    (s.version = "1.1"),
    (s.queue = []),
    (u = t.createElement(n)),
    (u.async = !0),
    (u.src = "https://static.ads-twitter.com/uwt.js"),
    (a = t.getElementsByTagName(n)[0]),
    a.parentNode.insertBefore(u, a));
})(window, document, "script");
twq("config", "od4zj");
`,
        }}
      />

      <script
        async
        type="text/partytown"
        src="https://www.googletagmanager.com/gtag/js?id=G-KKFY7ZXMPR"
      />

      <script
        type="text/partytown"
        dangerouslySetInnerHTML={{
          __html: `
window.dataLayer = window.dataLayer || [];
window.gtag = function gtag() {dataLayer.push(arguments);}
gtag("js", new Date());
gtag("config", "G-KKFY7ZXMPR");
gtag("config", "UA-158279173-1");
gtag("config", 'AW-11501053319');
`,
        }}
      />

      <script
        type="text/partytown"
        dangerouslySetInnerHTML={{
          __html: `
!function (w, d, t) {
w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)};

ttq.load('CE75N93C77U8PGLV9M8G');
ttq.page();
}(window, document, 'ttq');
`,
        }}
      />

      <script
        type="text/partytown"
        dangerouslySetInnerHTML={{
          __html: `
!function(e,t,n){function a(){var e=t.getElementsByTagName("script")[0],n=t.createElement("script");n.type="text/javascript",n.async=!0,n.src="https://beacon-v2.helpscout.net",e.parentNode.insertBefore(n,e)}if(e.Beacon=n=function(t,n,a){e.Beacon.readyQueue.push({method:t,options:n,data:a})},n.readyQueue=[],"complete"===t.readyState)return a();e.attachEvent?e.attachEvent("onload",a):e.addEventListener("load",a,!1)}(window,document,window.Beacon||function(){});
window.Beacon('init', 'fac65073-76cf-4598-82e8-809e6df4d376')
`,
        }}
      />
    </>
  );
}

Scripts.propTypes = {
  children: PropTypes.node,
};
