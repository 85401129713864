import * as React from "react";
import PropTypes from "prop-types";
import Logos from "./Logos";
import HeroVideo from "./HeroVideo";

export default function Hero({ isMobile }) {
  return (
    <div className="home-page__hero__box">
      <div className="home-page__hero__left">
        <h1 className="home-page__hero__title">
          <span className="home-page__hero__title-a">Autographs </span>
          <span className="home-page__hero__title-b">Livestreamed</span>
        </h1>

        <p className="home-page__hero__subtitle">
          Watch your favorite talent sign personalized autographs for you on
          camera. Delivered right to your door!
        </p>

        {!isMobile && <Logos />}
      </div>

      {!isMobile && <HeroVideo />}
    </div>
  );
}

Hero.propTypes = {
  isMobile: PropTypes.bool,
};
