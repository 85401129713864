import PropTypes from "prop-types";
import "./style.css";

export const FAST = "fast";
export const MODERATE = "moderate";
export const SLOW = "slow";
export const HIDDEN = "hidden";

const speeds = [FAST, MODERATE, SLOW, HIDDEN];

export const sortBySpeed = (direction) => (a, b) => {
  const indexA = speeds.indexOf(a);
  const indexB = speeds.indexOf(b);
  return direction === "asc" ? indexB - indexA : indexA - indexB;
};

function attributesFor({ speed, multisign }) {
  if (speed === FAST) {
    return {
      title: multisign
        ? "Ships fast! These talent are quick to fulfill their orders."
        : "Ships fast! This talent is quick to fulfill orders.",
      text: "Fast",
      className: FAST,
    };
  }

  if (speed === MODERATE) {
    return {
      title: multisign
        ? "Ships on time! These talent typically meet deadlines, but may require more time due to their schedules."
        : "Ships on time! This talent typically meets deadlines, but may require more time due to their schedule.",
      text: "Moderate",
      className: MODERATE,
    };
  }

  if (speed === SLOW) {
    return {
      title: multisign
        ? "Ships slowly! These talent may take longer to fulfill their orders due to their schedules."
        : "Ships slowly! This talent may take longer to fulfill orders due to their schedule.",
      text: "Slow",
      className: SLOW,
    };
  }

  return HIDDEN;
}

export default function FulfillmentSpeed({
  speed = MODERATE,
  multisign = false,
}) {
  const attributes = attributesFor({ speed, multisign });

  if (attributes === HIDDEN) {
    return null;
  }

  return (
    <div
      className={`fulfillment-speed ${attributes.className}`}
      title={attributes.title}
    >
      <svg
        focusable="false"
        viewBox="0 0 24 24"
        aria-hidden="true"
        fill="currentColor"
        className="fulfillment-speed__icon"
      >
        <path d="M16.24 7.76C15.07 6.59 13.54 6 12 6v6l-4.24 4.24c2.34 2.34 6.14 2.34 8.49 0 2.34-2.34 2.34-6.14-.01-8.48zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"></path>
      </svg>
      <p className="fulfillment-speed__text">{attributes.text}</p>
    </div>
  );
}

FulfillmentSpeed.propTypes = {
  speed: PropTypes.oneOf(speeds),
  multisign: PropTypes.bool,
};
