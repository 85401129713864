import { styled } from "@material-ui/core/styles";
import PropTypes from "prop-types";

const srcs = {
  desktop:
    "https://d2naapwr4zpnvs.cloudfront.net/misc/fire-bg-31980649ef5c915aaeafd350bdf3df64.webp",
  mobile:
    "https://d2naapwr4zpnvs.cloudfront.net/misc/fire-bg-mobile-31980649ef5c915aaeafd350bdf3df64.webp",
};

const StyledContainer = styled("div")(({ theme }) => ({
  padding: theme.spacing(6, 0, 3, 0),
  display: "flex",
  justifyContent: "center",
  background: "#282828",
  backgroundImage: `url(${srcs.desktop})`,
  backgroundSize: "cover",
  [theme.breakpoints.down("sm")]: {
    backgroundImage: `url(${srcs.mobile})`,
    padding: theme.spacing(6, 0, 2, 0),
  },
}));

const Container = styled("div")(({ theme }) => ({
  width: "100%",
  maxWidth: 1200,
  padding: `0 var(--gutter) 0 var(--gutter);`,
  display: "flex",
  justifyContent: "space-between",
  gap: theme.spacing(2),
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
}));

const StyledText = styled("p")(({ theme }) => ({
  ...theme.typography.body1,
  margin: 0,
  fontSize: theme.typography.pxToRem(16),
  color: "white",
  maxWidth: "72ch",
  [theme.breakpoints.down("sm")]: {
    fontSize: theme.typography.pxToRem(14),
  },
  [theme.breakpoints.down("xs")]: {
    textAlign: "center",
  },
}));

const StyledLink = styled("a")(({ theme }) => ({
  color: theme.palette.grey.main,
  fontSize: theme.typography.pxToRem(16),
  background: "white",
  padding: theme.spacing(2, 3.25, 2, 4),
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.boxShadow.buttonDefault,
  textDecoration: "none",
  fontWeight: 700,
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1),
  "&:hover": {
    background: theme.palette.primary.background,
    boxShadow: theme.boxShadow.buttonHover,
    cursor: "pointer",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: theme.typography.pxToRem(14),
    padding: theme.spacing(1.5, 2.25, 1.5, 3),
  },
}));

const TextContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  gap: theme.spacing(1),
  [theme.breakpoints.down("xs")]: {
    alignItems: "center",
  },
}));

const StyledLinkContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "flex-end",
  [theme.breakpoints.down("xs")]: {
    justifyContent: "center",
  },
}));

export default function Fire() {
  return (
    <StyledContainer>
      <Container>
        <TextContainer>
          <StyledText>
            In the wake of the devastating wildfires in the Los Angeles area,
            our hearts go out to all those affected and who have lost their
            homes and loved ones to the fires. This includes our team members,
            our talent and their families.
          </StyledText>

          <StyledText>
            To provide immediate support to all victims, Streamily will donate a
            portion of every purchase to directly aid those impacted by the
            fires.
          </StyledText>
        </TextContainer>

        <StyledLinkContainer>
          <StyledLink
            target="_blank"
            rel="noopener noreferrer"
            href="https://landing.streamily.com/la-wildfire-resource-guide"
          >
            <span>L.A. Wildfire Resource Guide</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <line x1="5" y1="12" x2="19" y2="12"></line>
              <polyline points="12 5 19 12 12 19"></polyline>
            </svg>
          </StyledLink>
        </StyledLinkContainer>
      </Container>
    </StyledContainer>
  );
}

Fire.propTypes = {
  isMobile: PropTypes.bool.isRequired,
};
