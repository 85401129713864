import * as React from "react";
import PropTypes from "prop-types";
import { Box } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import { PageSubheading } from "..";

const StyledPageContent = styled(Box)(({ theme }) => ({
  maxWidth: theme.breakpoints.values.xl,
  margin: "0",
  padding: theme.spacing(3),
  position: "relative",
  width: "100%",
  [theme.breakpoints.down("md")]: {
    padding: theme.spacing(2),
  },
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(1),
  },
}));

export default function DashboardPageContent({
  contentTitle,
  children,
  ...args
}) {
  return (
    <StyledPageContent {...args}>
      {contentTitle && <PageSubheading>{contentTitle}</PageSubheading>}
      {children}
    </StyledPageContent>
  );
}

DashboardPageContent.propTypes = {
  contentTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.node,
};
