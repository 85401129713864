import { Card } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";

const SoftCard = styled(Card)(({ theme }) => ({
  boxShadow: theme.boxShadow.soft,
  padding: theme.spacing(1.5),
  width: "100%",
  background: theme.palette.white,
  "&.presigned": {
    border: `2px solid #1A4857`,
  },
  "&.no-autograph": {
    border: `2px solid #1A572F`,
  },
}));

export default SoftCard;
