import { A, D, G, S } from "@mobily/ts-belt";
import * as React from "react";
import { useHistory, useLocation } from "react-router-dom";
import useSWR from "swr";
import { Product, SignType, withIncludes } from "../../domain";
import { fetcher } from "../../hooks";

export const MAX_PRICE = 20_000;

export const allSignedTypes = SignType.list().reduce((acc, type) => {
  acc[type] = true;
  return acc;
}, {});

export const defaultFilter = {
  order: "ra",
  tags: {},
  talents: {},
  type: {},
  maxPrice: 0,
  minPrice: 0,
  character: {},
  franchise: {},
  signedType: {},
  rank: 0,
  limit: 24,
  name: "",
};

const defaultFilters = {
  order: [
    { id: "ca", name: "Oldest" },
    { id: "cd", name: "Newest" },
    { id: "pa", name: "Price: Low to High" },
    { id: "pd", name: "Price: High to Low" },
    { id: "ra", name: "Most Popular" },
  ],
  tags: [],
  talents: [],
  type: [],
  character: [],
  franchise: [],
};

const defaultPagination = {
  previousPageId: null,
  currentPageId: null,
  nextPageId: null,
  totalResults: 0,
  currentResults: 0,
  pageSize: 24,
  hasNext: false,
  hasPrevious: false,
};

function isValidOrder(order) {
  return (
    G.isString(order) &&
    S.isNotEmpty(order) &&
    defaultFilters.order.some((filter) => filter.id === order)
  );
}

function isValidPrice(price) {
  return (
    G.isNumber(price) &&
    !Number.isNaN(price) &&
    price >= 0 &&
    price <= MAX_PRICE
  );
}

function isValidNumber(num) {
  return G.isNumber(num) && !Number.isNaN(num) && num >= 0;
}

function isValidSignedType(signedTypes) {
  return (
    G.isArray(signedTypes) && signedTypes.every((type) => allSignedTypes[type])
  );
}

const keyMap = {
  order: "o",
  tags: "t",
  talents: "ta",
  type: "ty",
  maxPrice: "mp",
  minPrice: "mi",
  character: "c",
  franchise: "f",
  signedType: "st",
  rank: "r",
  limit: "l",
  name: "q",
};

function toMap(acc, value) {
  acc[value] = true;
  return acc;
}

const paramMap = {
  o: {
    name: "order",
    parser: (value) => (isValidOrder(value) ? value : "ra"),
  },
  t: {
    name: "tags",
    parser: (value) => value.split(",").reduce(toMap, {}),
  },
  ta: {
    name: "talents",
    parser: (value) => value.split(",").reduce(toMap, {}),
  },
  ty: {
    name: "type",
    parser: (value) => value.split(",").reduce(toMap, {}),
  },
  mp: {
    name: "maxPrice",
    parser: (value) => (isValidPrice(Number(value)) ? Number(value) : 0),
  },
  mi: {
    name: "minPrice",
    parser: (value) => (isValidPrice(Number(value)) ? Number(value) : 0),
  },
  c: {
    name: "character",
    parser: (value) => value.split(",").reduce(toMap, {}),
  },
  f: {
    name: "franchise",
    parser: (value) => value.split(",").reduce(toMap, {}),
  },
  st: {
    name: "signedType",
    parser: (value) => {
      const values = value.split(",");

      if (isValidSignedType(values)) {
        return values.reduce((acc, type) => {
          acc[type] = true;
          return acc;
        }, {});
      }

      return {};
    },
  },
  r: {
    name: "rank",
    parser: (value) => (isValidNumber(Number(value)) ? Number(value) : 0),
  },
  l: {
    name: "limit",
    parser: (value) => (isValidNumber(Number(value)) ? Number(value) : 24),
  },
  q: {
    name: "name",
    parser: (value) => value,
  },
};

function parseDefaultsFromParams(search) {
  const params = new URLSearchParams(search);

  if (params.size === 0) {
    return defaultFilter;
  }

  console.log(params);

  const result = {};

  for (const [key, value] of params.entries()) {
    const mappedKey = paramMap[key];

    if (!mappedKey) {
      continue;
    }

    const { name, parser } = mappedKey;

    const parsedValue = parser(value);

    result[name] = parsedValue;

    console.log({ key, value, name, parsedValue });
  }

  console.log(result);

  return D.merge(defaultFilter, result);
}

export function useFilterParams() {
  const location = useLocation();
  const history = useHistory();

  const [filter, setFilter] = React.useState(() =>
    parseDefaultsFromParams(location.search),
  );

  const updateFilterAndSetParams = React.useCallback(
    (updateFnOrFilter) => {
      let updateFn = updateFnOrFilter;

      if (typeof updateFn !== "function") {
        updateFn = () => updateFnOrFilter;
      }

      setFilter((filter) => {
        const newFilter = updateFn(filter);

        const search = Object.entries(newFilter)
          .reduce((result, [key, value]) => {
            const mappedKey = keyMap[key];

            if (G.isString(value)) {
              result.set(mappedKey, value);
            } else if (G.isNumber(value)) {
              result.set(mappedKey, String(value));
            } else if (G.isObject(value)) {
              const values = Object.keys(value).filter((key) => value[key]);

              if (values.length > 0) {
                result.set(mappedKey, values.join(","));
              }
            } else if (G.isArray(value)) {
              if (value.length > 0) {
                result.set(mappedKey, value.join(","));
              }
            }

            return result;
          }, new URLSearchParams())
          .toString();

        history.replace({ search });

        return newFilter;
      });
    },
    [history],
  );

  return { filter, setFilter: updateFilterAndSetParams };
}

function hasMaxPrice(maxPrice) {
  return maxPrice > 0;
}

function hasMinPrice({ minPrice, maxPrice }) {
  return minPrice > 0 && (minPrice < maxPrice || !hasMaxPrice(maxPrice));
}

function hasName(name) {
  return G.isString(name) && S.isNotEmpty(name);
}

function hasRank(rank) {
  return G.isNumber(rank) && rank > 0;
}

function paramsFor({
  character,
  franchise,
  maxPrice,
  minPrice,
  name,
  order,
  tags,
  talents,
  type,
  signedType,
  rank,
}) {
  const params = new URLSearchParams({
    order,
  });

  if (hasName(name)) {
    params.set("name", name);
  }

  if (hasMaxPrice(maxPrice)) {
    params.set("max_price", maxPrice);
  }

  if (hasMinPrice({ minPrice, maxPrice })) {
    params.set("min_price", minPrice);
  }

  if (tags) {
    const tagsArr = Object.keys(tags).filter((key) => tags[key]);
    if (tagsArr.length > 0) {
      params.set("tags", tagsArr.join(","));
    }
  }

  if (talents) {
    const talentsArr = Object.keys(talents).filter((key) => talents[key]);
    if (talentsArr.length > 0) {
      params.set("talents", talentsArr.join(","));
    }
  }

  if (type) {
    const typeArr = Object.keys(type).filter((key) => type[key]);
    if (typeArr.length > 0) {
      params.set("type", typeArr.join(","));
    }
  }

  if (character) {
    const characterArr = Object.keys(character).filter((key) => character[key]);
    if (characterArr.length > 0) {
      params.set("character", characterArr.join(","));
    }
  }

  if (franchise) {
    const franchiseArr = Object.keys(franchise).filter((key) => franchise[key]);
    if (franchiseArr.length > 0) {
      params.set("franchise", franchiseArr.join(","));
    }
  }

  if (signedType) {
    const signedTypeArr = Object.keys(signedType).filter(
      (key) => signedType[key],
    );
    if (signedTypeArr.length > 0) {
      params.set("signed_type", signedTypeArr.join(","));
    }
  }

  if (hasRank(rank)) {
    params.set("rank", rank);
  }

  return params;
}

export function useProducts(filter = defaultFilter) {
  const { data, error, isValidating, mutate } = useSWR(
    `/api/products/browse?${paramsFor(filter)}`,
    fetcher,
    { keepPreviousData: true },
  );

  const products = React.useMemo(() => {
    if (!G.isArray(data?.products) || A.isEmpty(data.products)) {
      return [];
    }

    return data.products.map((product) => withIncludes(Product.from(product)));
  }, [data]);

  const pagination = React.useMemo(() => {
    return data?.pagination || defaultPagination;
  }, [data]);

  return {
    error,
    isError: Boolean(error),
    isLoading: !data && !error,
    isValidating,
    mutate,
    products,
    pagination,
  };
}

function filterParamsFor({ signedType }) {
  const params = new URLSearchParams();

  if (signedType) {
    const signedTypeArr = Object.keys(signedType).filter(
      (key) => signedType[key],
    );

    if (signedTypeArr.length > 0) {
      params.set("signed_types", signedTypeArr.join(","));
    }
  }

  return params;
}

export function useFilters(filter = defaultFilter) {
  const { data, error, isValidating, mutate } = useSWR(
    `/api/products/filters?${filterParamsFor(filter)}`,
    fetcher,
    { keepPreviousData: true },
  );

  const filters = React.useMemo(() => {
    return data?.filters || defaultFilters;
  }, [data]);

  return {
    error,
    filters,
    isError: Boolean(error),
    isLoading: !data && !error,
    isValidating,
    mutate,
  };
}
