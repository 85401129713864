import { Box, Step, StepLabel, Stepper, Typography } from "@material-ui/core";
import { green, grey } from "@material-ui/core/colors";
import { styled } from "@material-ui/core/styles";
import { format } from "date-fns";
import PropTypes from "prop-types";
import * as React from "react";
import { CoaAddon } from "../../domain/addon";
import { SignType } from "../../domain";
import { InfoTooltip, SmallAvatar } from "../../theme";
import Steps from "./Steps";
import { featureFlags } from "../../util";

const StyledImage = styled("img")(({ theme }) => ({
  borderRadius: theme.spacing(1),
  height: "132px",
  margin: 0,
  objectFit: "cover",
  verticalAlign: "middle",
  width: "132px",
}));

const StyledStepper = styled(Stepper)(({ theme }) => ({
  borderRadius: theme.spacing(1),
}));

const StyledLabel = styled("p")(({ theme }) => ({
  ...theme.typography.caption,
  alignItems: "center",
  border: `1px solid ${theme.palette.grey[500]}`,
  borderRadius: 4,
  color: theme.palette.text.primary,
  display: "flex",
  fontWeight: 500,
  gap: 8,
  justifyContent: "center",
  lineHeight: 1.2,
  margin: 0,
  padding: "8px 12px 8px 8px",
  pointerEvents: "none",
  textAlign: "center",
  userSelect: "none",
}));

function orderDateFor({ created }) {
  try {
    const date = new Date(created);
    return `Ordered on ${format(date, "MMMM dd, yyyy")}`;
  } catch (err) {
    console.error(err);
    return "";
  }
}

function signingTextFor({ signedType, signingText, expectedSigningText }) {
  if (!featureFlags.SHOW_EXPECTED_SIGNING_TEXT) {
    return "";
  }

  if (SignType.isAudioShoutout(signedType) || SignType.isShoutout(signedType)) {
    return "";
  }

  const text = signingText || expectedSigningText;

  if (!text) {
    return "";
  }

  return (
    <>
      <br />
      {text}
    </>
  );
}

export default function Order({ order }) {
  const orderSteps = React.useMemo(() => {
    return Steps.for(order);
  }, [order]);

  const { step, description } = orderSteps.getStatus();

  const icon = React.useMemo(() => {
    if (!order.hasCertOfAuth) {
      return null;
    }

    const coaAddon = new CoaAddon();

    return (
      <StyledLabel>
        <coaAddon.Icon />
        <span>Certified Authentic</span>
      </StyledLabel>
    );
  }, [order]);

  return (
    <Box data-testid="wheres-my-order__order" minHeight="25vh">
      <Box
        alignItems="flex-start"
        display="flex"
        justifyContent="space-between"
      >
        <div>
          <Typography gutterBottom variant="h6">
            Order Details
          </Typography>

          <Typography
            data-testid="wheres-my-order__order-number"
            gutterBottom
            variant="body2"
          >
            Order Number: {order.orderNumber}
          </Typography>

          <Typography variant="body2">
            {orderDateFor(order)}
            {signingTextFor(order)}
          </Typography>
        </div>

        {icon}
      </Box>

      <Box mt={2}>
        <StyledStepper activeStep={step}>
          {orderSteps.steps.map((step, i) => (
            <Step
              data-testid={`wheres-my-order__step-${i + 1}`}
              key={step.label}
            >
              <StepLabel>
                {step.label}
                <InfoTooltip size="small" content={step.description} />
              </StepLabel>
            </Step>
          ))}
        </StyledStepper>

        <Box maxWidth="52ch" my={2}>
          <Typography
            data-testid="wheres-my-order__order-description"
            variant="body2"
            color="textSecondary"
          >
            {description}
          </Typography>
        </Box>
      </Box>

      <Box mt={4} display="flex" gridGap="32px">
        <Box display="flex" flexDirection="column" gridGap="8px">
          <Typography gutterBottom variant="subtitle2">
            Products
          </Typography>

          <StyledImage
            data-testid="wheres-my-order__order-image"
            src={order.productImageUrl}
          />

          <Typography variant="body2">{order.productName}</Typography>
        </Box>

        <Box
          data-testid="wheres-my-order__order-signers"
          display="flex"
          flexDirection="column"
          gridGap="8px"
          flex="1"
        >
          <Typography gutterBottom variant="subtitle2">
            Talent
          </Typography>

          {order.fulfillers.map((fulfiller) => (
            <Box
              alignItems="center"
              display="grid"
              gridTemplateColumns="50px 1fr 150px"
              key={fulfiller.influencerId}
              maxWidth="480px"
            >
              <SmallAvatar
                style={{
                  border: `2px solid ${grey[400]}`,
                  borderColor: fulfiller.fulfilled ? green[600] : grey[400],
                }}
                src={fulfiller.avatarUrl}
              />

              <Typography variant="body2">{fulfiller.name}</Typography>

              <Typography variant="body2">
                {orderSteps.signerTextFor(fulfiller)}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
}

Order.propTypes = {
  order: PropTypes.shape({
    expectedSigningText: PropTypes.string,
    hasCertOfAuth: PropTypes.bool.isRequired,
    orderNumber: PropTypes.string.isRequired,
    productImageUrl: PropTypes.string.isRequired,
    productName: PropTypes.string.isRequired,
    received: PropTypes.bool.isRequired,
    shipped: PropTypes.bool.isRequired,
    signed: PropTypes.bool.isRequired,
    signingText: PropTypes.string,
    created: PropTypes.string.isRequired,
    fulfillers: PropTypes.arrayOf(
      PropTypes.shape({
        influencerId: PropTypes.string.isRequired,
        avatarUrl: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        fulfilled: PropTypes.bool.isRequired,
      }),
    ).isRequired,
  }),
};
