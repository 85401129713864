import { Box, Divider } from "@material-ui/core";
import { A, B, D } from "@mobily/ts-belt";
import { useAtom } from "jotai";
import PropTypes from "prop-types";
import * as React from "react";
import {
  useActiveInfluencer,
  useAgentsIds,
  useCurrentUser,
} from "../../../hooks";
import { Form, InfoTooltip } from "../../../theme";
import { totalsFor } from "../Step2/utils";
import * as styles from "../styles";
import {
  AGENT_TYPES,
  format,
  formatAgentToFee,
  MAX_PRODUCT_PRICE,
  MIN_PRODUCT_PRICE,
} from "../utils";
import CurrentSigner from "./CurrentSigner";
import OtherSigner from "./OtherSigner";
import SignerSearch from "./SignerSearch";

function ProductSignersStep({
  productAtom,
  productAgentsAtom,
  productSignersAtom,
}) {
  const [signers, setSigners] = useAtom(productSignersAtom);
  const [productAgents, setProductAgents] = useAtom(productAgentsAtom);
  const [product, setProduct] = useAtom(productAtom);
  const currentUser = useCurrentUser();
  const activeInfluencer = useActiveInfluencer();

  const { agents } = useAgentsIds(signers.map((signer) => signer.influencerId));

  React.useEffect(() => {
    if (!agents.length) {
      return;
    }

    setProductAgents((productAgents) => {
      return agents.reduce((acc, agent) => {
        const existingAgents = productAgents[agent.influencerId] ?? [];
        const newAgents = acc[agent.influencerId] ?? [];

        const foundAgent = existingAgents.find(
          (a) =>
            a.userId === agent.userId && a.influencerId === agent.influencerId,
        );

        if (!foundAgent) {
          newAgents.push(formatAgentToFee(agent));
        } else {
          // Set the amount to the found agent's amount to support "removing"
          // the agent (ie. setting the amount to $0)
          newAgents.push(
            D.merge(formatAgentToFee(agent), {
              type: foundAgent.type,
              amount: foundAgent.amount,
            }),
          );
        }

        acc[agent.influencerId] = newAgents;

        return acc;
      }, {});
    });
  }, [agents, setProductAgents]);

  const { currentSigner, otherSigners, influencerIds } = React.useMemo(() => {
    const otherSigners = [];
    const influencerIds = [];

    let currentSigner;

    signers.forEach((signer) => {
      influencerIds.push(signer.influencerId);

      if (signer.influencerId === activeInfluencer.influencerId) {
        currentSigner = signer;
      } else {
        otherSigners.push(signer);
      }
    });

    return { currentSigner, otherSigners, influencerIds };
  }, [signers, activeInfluencer]);

  const handleAddSigner = React.useCallback(
    (signer) => {
      setSigners(A.append(signer));
    },
    [setSigners],
  );

  const handleRemoveSigner = React.useCallback(
    (signer) => {
      setSigners(A.filter((s) => s.influencerId !== signer.influencerId));
    },
    [setSigners],
  );

  const handleUpdateAmount = React.useCallback(
    (currentSigner, isBlur) => {
      return (evt) => {
        const { target } = evt;

        let newValue = Math.round(
          Number.parseFloat(target.value.replace(/[^0-9.-]/g, "")),
        );

        // If the value is NaN, reset the value to the current amount
        if (Number.isNaN(newValue) && isBlur) {
          target.value = format(currentSigner.splitAmount);
          return;
        }

        if (Number.isNaN(newValue)) {
          newValue = 0;
        }

        const splitAmount = Math.max(
          MIN_PRODUCT_PRICE,
          Math.min(newValue * 100, MAX_PRODUCT_PRICE),
        );

        const mapFn = (signer) =>
          signer === currentSigner ? { ...currentSigner, splitAmount } : signer;

        setSigners(A.map(mapFn));

        if (!isBlur && newValue === 0) {
          target.value = "";
        }

        target.value = format(splitAmount);
      };
    },
    [setSigners],
  );

  const handleUpdateNoFulfillment = React.useCallback(
    (currentSigner) => {
      return (evt) => {
        const newValue = evt.target.checked;
        const mapFn = (signer) =>
          signer === currentSigner
            ? { ...currentSigner, noFulfillment: newValue }
            : signer;
        setSigners(A.map(mapFn));
      };
    },
    [setSigners],
  );

  const handleRemoveAgent = React.useCallback(
    (agent) => {
      setProductAgents((productAgents) => {
        const { influencerId, userId } = agent;
        const existingAgents = productAgents[influencerId] ?? [];

        return D.merge(productAgents, {
          [influencerId]: existingAgents.map((agent) =>
            agent.userId === userId
              ? { ...agent, type: AGENT_TYPES.AGENT_MANUAL, amount: 0 }
              : agent,
          ),
        });
      });
    },
    [setProductAgents],
  );

  const handleAddAgent = React.useCallback(
    (agentToUpdate) => {
      setProductAgents((productAgents) => {
        const { influencerId, userId } = agentToUpdate;
        const existingAgents = productAgents[influencerId] ?? [];
        const productAgent = agents.find((agent) => agent.userId === userId);

        if (!productAgent) {
          return productAgents;
        }

        return D.merge(productAgents, {
          [influencerId]: existingAgents.map((agent) =>
            agent.userId === userId
              ? { ...agent, ...formatAgentToFee(productAgent) }
              : agent,
          ),
        });
      });
    },
    [agents, setProductAgents],
  );

  const { total, bump: calculatedBump } = React.useMemo(
    () => totalsFor({ product, agents: productAgents, signers }),
    [product, productAgents, signers],
  );

  const label = React.useMemo(() => {
    const isNotOwner =
      activeInfluencer.ownerEmail !== currentUser.email || currentUser.isAdmin;

    const phrase =
      isNotOwner && currentSigner ? ` does ${currentSigner?.name}` : " do you";

    return `How much ${phrase} want to make`;
  }, [currentUser, currentSigner, activeInfluencer]);

  const onBlurBump = (evt) => {
    const { target } = evt;
    const newValue = Math.round(Number(target.value)) * 100;
    setProduct(D.set("productBump", newValue));
    target.value = format(newValue);
  };

  const bump = React.useMemo(() => {
    if (product.fixedBump) {
      return format(product.productBump);
    }

    if (calculatedBump !== product.productBump) {
      return format(calculatedBump);
    }

    return format(product.productBump);
  }, [calculatedBump, product.fixedBump, product.productBump]);

  const canSeeOtherSigners = currentUser.isAdmin;

  return (
    <Box display="flex" flexDirection="column" gridGap="32px">
      <Box display="flex" flexDirection="column" gridGap="32px">
        {currentSigner && (
          <CurrentSigner
            agents={productAgents[currentSigner?.influencerId]}
            currentSigner={currentSigner}
            currentUser={currentUser}
            handleAddAgent={handleAddAgent}
            handleRemoveAgent={handleRemoveAgent}
            handleRemoveSigner={handleRemoveSigner}
            handleUpdateAmount={handleUpdateAmount}
            handleUpdateNoFulfillment={handleUpdateNoFulfillment}
            label={label}
          />
        )}

        {A.isNotEmpty(otherSigners) &&
          canSeeOtherSigners &&
          otherSigners.map((signer) => (
            <OtherSigner
              agents={productAgents[signer.influencerId]}
              currentUser={currentUser}
              handleAddAgent={handleAddAgent}
              handleRemoveAgent={handleRemoveAgent}
              handleRemoveSigner={handleRemoveSigner}
              handleUpdateAmount={handleUpdateAmount}
              handleUpdateNoFulfillment={handleUpdateNoFulfillment}
              key={signer.influencerId}
              signer={signer}
            />
          ))}
      </Box>

      <Box display="flex" flexDirection="column">
        <Box
          display="flex"
          gridGap="0px 16px"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
        >
          <styles.Label>Platform Fee (approx)</styles.Label>

          <Form.Input
            className="white-filled"
            type="number"
            inputProps={{ size: 2 }}
            InputProps={{ startAdornment: "$" }}
            value={format(calculatedBump)}
            size="small"
            readOnly
          />
        </Box>

        <Divider />

        <Box
          display="flex"
          gridGap="0px 16px"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
        >
          <styles.Label>
            Total Product Price
            <InfoTooltip
              title="Total Product Price"
              content="This amount also includes fees for multiple signers"
              size="small"
            />
          </styles.Label>

          <Form.Input
            className="white-filled"
            type="number"
            inputProps={{ size: 2 }}
            InputProps={{ startAdornment: "$" }}
            value={format(total + calculatedBump)}
            size="small"
            readOnly
          />
        </Box>
      </Box>

      {currentUser.isAdmin && (
        <>
          <Box display="flex" flexDirection="column" gridGap="8px">
            <styles.Label>Platform Fee</styles.Label>

            <Box display="flex" alignItems="center" gridGap="16px">
              <Form.Input
                value={bump}
                disabled={!product.fixedBump}
                size="small"
                placeholder="Fee"
                InputProps={{ startAdornment: "$" }}
                onChange={(evt) => {
                  const newValue = Math.round(Number(evt.target.value)) * 100;
                  setProduct(D.set("productBump", newValue));
                }}
                onBlur={onBlurBump}
                inputProps={{ type: "number", size: 2, min: 0, maxLength: 4 }}
              />

              <Form.SmallCheckbox
                checked={Boolean(product.fixedBump)}
                onChange={() => setProduct(D.update("fixedBump", B.not))}
                label="Fixed Platform Fee"
              />
            </Box>
          </Box>

          <SignerSearch
            influencerIds={influencerIds}
            onAddSigner={handleAddSigner}
          />
        </>
      )}
    </Box>
  );
}

ProductSignersStep.propTypes = {
  productAgentsAtom: PropTypes.object.isRequired,
  productAtom: PropTypes.object.isRequired,
  productSignersAtom: PropTypes.object.isRequired,
  productTagsAtom: PropTypes.object.isRequired,
  stateAtom: PropTypes.object.isRequired,
};

export default {
  Component: ProductSignersStep,
  title: "Product Pricing",
};
