import * as React from "react";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import { useCartProduct } from "../../../../hooks";
import { Influencer, Money, Product } from "../../../../domain";
import { ErrorUI, InfoTooltip } from "../../../../theme";

const ProductModalHeaderPrice = React.memo(function ProductModalHeaderPrice({
  product,
  influencer,
}) {
  const { cartProduct, isLoading, isError } = useCartProduct(product.productId);

  if (isLoading) {
    return (
      <StyledProductName variant="h6">
        {Money.add(product.price, product.productBump).format()}
        {Boolean(influencer) && Boolean(influencer.showPriceDisclaimer) && (
          <InfoTooltip content="Price includes platform fee" />
        )}
      </StyledProductName>
    );
  }

  if (isError) {
    return <ErrorUI message="Unable to load price" />;
  }

  return (
    <StyledProductName variant="h6">
      {cartProduct.subtotal.format()}
      {Boolean(influencer) && Boolean(influencer.showPriceDisclaimer) && (
        <InfoTooltip content="Price includes platform fee" />
      )}
    </StyledProductName>
  );
});

ProductModalHeaderPrice.propTypes = {
  product: PropTypes.instanceOf(Product),
  influencer: PropTypes.instanceOf(Influencer),
};

const StyledProductName = styled(Typography)(({ theme }) => ({
  fontFamily: theme.typography.interFontStack,
  fontWeight: theme.typography.semiBold,
  margin: 0,
  lineHeight: 1,
}));

export default ProductModalHeaderPrice;
