import { Box, Grid } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import { Skeleton } from "@material-ui/lab";
import PropTypes from "prop-types";
import * as React from "react";
import { useInfluencerRoute, useMatchesMdDown } from "../../../hooks";
import { ErrorUI, GreyText, PageHeading } from "../../../theme";
import FavouriteButton from "../../FavouriteButton";
import FulfillmentSpeed from "../../FulfillmentSpeed";
import LiveAvatar from "./LiveAvatar";

const ShopDetails = React.memo(function ShopDetails() {
  const { influencer, isLoading, isError } = useInfluencerRoute();
  const matchesMd = useMatchesMdDown();

  if (isLoading) {
    return <LoadingUI matchesMd={matchesMd} />;
  }

  if (isError) {
    return <ErrorUI message="Unable to load shop data" />;
  }

  return (
    <Box pt={matchesMd ? 2 : 5} pb={1}>
      <Grid container spacing={1} alignItems="center">
        <Grid item>
          <LiveAvatar
            isLive={influencer.isLive}
            avatarUrl={influencer.avatarUrl}
          />
        </Grid>

        <Grid item>
          <PageHeading component="h1">{influencer.name}</PageHeading>
        </Grid>

        <Grid item>
          <Box pl={2}>
            <FavouriteButton influencerId={influencer.influencerId} />
          </Box>
        </Grid>

        <Grid item xs={12}>
          <FulfillmentSpeed speed={influencer.fulfillmentSpeed} />
        </Grid>

        <Grid item xs={12}>
          <StyledBox
            py={matchesMd ? 0 : 1}
            mt={matchesMd && influencer.caption ? 1 : 0}
          >
            <GreyText variant="body2">{influencer.caption}</GreyText>
          </StyledBox>
        </Grid>
      </Grid>
    </Box>
  );
});

const StyledBox = styled(Box)(() => ({ maxWidth: "75ch" }));

function LoadingUI({ matchesMd }) {
  return (
    <Box pt={matchesMd ? 2 : 5} pb={1}>
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs="auto">
          <Skeleton width={45} height={45} variant="circle" />
        </Grid>

        <Grid item xs={3}>
          <Skeleton height={36} />
        </Grid>

        <Grid item xs={2}>
          <Skeleton height={36} />
        </Grid>

        <Grid container item xs={12}>
          <Grid item xs={12}>
            <Skeleton />
          </Grid>

          <Grid item xs={12}>
            <Skeleton />
          </Grid>

          <Grid item xs={12}>
            <Skeleton />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

LoadingUI.propTypes = {
  matchesMd: PropTypes.bool.isRequired,
};

export default ShopDetails;
