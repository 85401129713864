export const SITE_LOGO =
  "https://d2naapwr4zpnvs.cloudfront.net/misc/streamily-logo-full-without-tagline.png";

export const SITE_VIDEO_PLACEHOLDER =
  "https://d2naapwr4zpnvs.cloudfront.net/misc/homepage-phone-placeholder.webp";

export const SITE_VIDEO =
  "https://d3qdxs9s8vjwda.cloudfront.net/misc/homepage-phone-video.mp4";

export const talentEmails = [
  {
    key: "talentDailySales",
    description: "New Sales",
    long: "Watch the sales come in! See a daily breakdown of what you've sold.",
  },
  {
    key: "talentIncompleteOrders",
    description: "Incomplete Orders",
    long: "Never miss a beat! Get weekly updates about orders waiting for your attention and keep your fans happy.",
  },
  {
    key: "talentEventReminder",
    description: "Upcoming Event Reminders",
    long: "Get ready to sign! Stay prepared for your Livestream event with tips and tools to make your event a success.",
  },
];
