import { D, G } from "@mobily/ts-belt";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";

function toFilteredArray(map) {
  return Object.entries(map).filter(([, value]) => Boolean(value));
}

function toParam(arr) {
  return arr.map(([key]) => key).join(",");
}

function toState(arr) {
  return Object.fromEntries(arr);
}

export function createParser({ name, key, getter }) {
  return { [name]: { getter, setter: toFilteredArray, toState, toParam, key } };
}

export function useStateFromParams(parsers) {
  const history = useHistory();
  const location = useLocation();
  const [state, setState] = React.useState(() => {
    const params = new URLSearchParams(location.search);

    const initialState = Object.entries(parsers).reduce(
      (acc, [key, parser]) => {
        acc[key] = parser.getter(params.get(parser.key));
        return acc;
      },
      {},
    );

    return initialState;
  });

  const setStateAndUpdateParams = React.useCallback(
    (updateFnOrTypes) => {
      let updateFn = updateFnOrTypes;

      if (!G.isFunction(updateFnOrTypes)) {
        updateFn = () => updateFnOrTypes;
      }

      setState((prevState) => {
        const newState = updateFn(prevState);

        if (D.isEmpty(newState)) {
          history.replace({ search: "" });
          return newState;
        }

        const params = new URLSearchParams();

        Object.entries(parsers).forEach(([key, parser]) => {
          const list = parser.setter(newState[key]);

          if (list.length > 0) {
            params.set(parser.key, parser.toParam(list));
          }

          newState[key] = parser.toState(list);
        });

        if (!params.size) {
          history.replace({ search: "" });
        } else {
          history.replace({ search: params.toString() });
        }

        return newState;
      });
    },
    [history, parsers],
  );

  return [state, setStateAndUpdateParams];
}
