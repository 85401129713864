import { Button, Snackbar } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import RefreshIcon from "@material-ui/icons/Refresh";
import { atom, useAtom } from "jotai";
import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import useSWR from "swr";
import { fetcher } from "../hooks";
import { SHOW_VERSION_MODAL } from "../util/featureFlags";

const StyledAlert = styled("div")(({ theme }) => ({
  background: theme.palette.background.paper,
  ...theme.typography.body2,
  maxWidth: "48ch",
  display: "flex",
  alignItems: "center",
  boxShadow: theme.shadows[6],
  padding: theme.spacing(1.5),
  borderRadius: theme.shape.borderRadius,
}));

function Alert({ message, onClose }) {
  return (
    <StyledAlert role="alert">
      <span>{message}</span>
      <Button
        startIcon={<RefreshIcon />}
        variant="text"
        color="primary"
        onClick={onClose}
        size="small"
      >
        RELOAD
      </Button>
    </StyledAlert>
  );
}

Alert.propTypes = {
  message: PropTypes.string,
  onClose: PropTypes.func,
};

const versionAtom = atom();

function wantsHardRefresh({ currentVersion, data }) {
  if (data.hardRefresh) {
    return true;
  }

  return Math.abs(data.version - currentVersion) > 1;
}

export default function VersionProvider({ children }) {
  const [currentVersion, setCurrentVersion] = useAtom(versionAtom);
  const { data, error } = useSWR("/api/version", fetcher);
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = useCallback(() => {
    if (!data?.version) {
      return;
    }

    setIsOpen(false);
    setCurrentVersion(data.version);
    window.location.reload();
  }, [data?.version, setCurrentVersion]);

  useEffect(() => {
    if (!data && !error) {
      return;
    }

    if (error) {
      return;
    }

    if (!currentVersion && data?.version) {
      setCurrentVersion(data?.version);
      return;
    }

    if (!data?.version || currentVersion === data?.version) {
      return;
    }

    if (wantsHardRefresh({ currentVersion, data }) || !SHOW_VERSION_MODAL) {
      setCurrentVersion(data.version);
      window.location.reload();
      return;
    }

    if (SHOW_VERSION_MODAL) {
      setIsOpen(true);
    }
  }, [error, currentVersion, data, setCurrentVersion]);

  if (SHOW_VERSION_MODAL) {
    return (
      <>
        {children}
        <Snackbar open={isOpen}>
          <Alert
            message="A new version of the site is available. Please reload the page to get the latest version."
            onClose={handleClose}
          />
        </Snackbar>
      </>
    );
  }

  return children;
}

VersionProvider.propTypes = {
  children: PropTypes.node,
};
