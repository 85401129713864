import useSWR from "swr";
import fetcher from "./fetcher";

const baseEndpoint = "/api/userAccount";

export function useUserAccount(userAccountId) {
  const { data, error, mutate } = useSWR(
    userAccountId ? `${baseEndpoint}/${userAccountId}` : null,
    fetcher,
  );
  return {
    account: data?.userAccount,
    isUserAccountLoading: !data && !error,
    isUserAccountError: Boolean(error),
    error,
    refresh: mutate,
  };
}

export function useUserAccountNotifications(userAccountId) {
  const { data, error, mutate } = useSWR(
    userAccountId
      ? `${baseEndpoint}/users/notifications/${userAccountId}`
      : null,
    fetcher,
  );

  return {
    notifications: data?.notifications,
    isUserAccountLoading: !data && !error,
    isUserAccountError: Boolean(error),
    error,
    refresh: mutate,
  };
}

export function useUserAccountOwner(influencerId) {
  const { data, error, mutate } = useSWR(
    influencerId ? `${baseEndpoint}/owners/${influencerId}` : null,
    fetcher,
  );
  return {
    owner: data?.owner,
    isOwnerLoading: !data && !error,
    isOwnerError: Boolean(error),
    error,
    refresh: mutate,
  };
}

export function useUserAccountInfluencer(influencerId) {
  const { data, error, mutate } = useSWR(
    influencerId ? `${baseEndpoint}/influencers/${influencerId}` : null,
    fetcher,
  );
  return {
    influencer: data?.influencer,
    isOwnerLoading: !data && !error,
    isOwnerError: Boolean(error),
    error,
    refresh: mutate,
  };
}
