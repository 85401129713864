import { styled } from "@material-ui/core/styles";
import { B, D } from "@mobily/ts-belt";
import PropTypes from "prop-types";
import * as React from "react";
import { SignType } from "../../domain";
import SignTypeBadge from "../SignTypeBadge";

const StyledBox = styled("div")(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(1),
  flexWrap: "wrap",
  [theme.breakpoints.down("sm")]: {
    flexWrap: "nowrap",
    overflowX: "auto",
  },
}));

export default function SignedTypeSelect({ initialState, onChange, options }) {
  const [signedTypes, setSignedTypes] = React.useState(initialState);

  React.useEffect(() => {
    onChange(signedTypes);
  }, [signedTypes, onChange]);

  const list = Array.isArray(options) ? options : SignType.list();

  if (list.length < 2) {
    return null;
  }

  return (
    <StyledBox>
      {list.map((signedType) => (
        <SignTypeBadge
          active={Boolean(signedTypes[signedType])}
          key={signedType}
          label={SignType.toLabel(signedType)}
          signedType={signedType}
          onClick={() => {
            setSignedTypes(D.update(signedType, B.not));
          }}
        />
      ))}
    </StyledBox>
  );
}

SignedTypeSelect.propTypes = {
  initialState: PropTypes.object,
  onChange: PropTypes.func,
  options: PropTypes.array,
};
